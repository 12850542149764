<template>
  <Dialog
    :header="varietyName + ' - Details'"
    :closable="false"
    :draggable="false"
    :modal="true"
    :style="style"
    :visible="visible"
  >
    <Button @click="toggleShow"
        class="close"
        icon="pi pi-times" 
    />
    <div v-for="attr in resultAttributes" :key="attr.key">
        <template v-if="attr.value !== 'NULL'">
            <div v-if="attr.key === 'Questions'">
                <div v-for="(val, index) in separateQuestion(attr.value)" :key="index">
                    <b>{{ val }}: </b> {{ showAnswer(index) }}
                </div>
            </div>
            <template v-else>

                <div v-if="!isEmpty(attr.value) && attr.key === 'Plant Introduction Number'">
                    <b>Plant Inventory (PI) Number:</b> {{ attr.value }}
                </div>
                <div v-else-if="attr.key === 'Certified Seed'">
                    <template v-if="attr.value == 0">
                        <b>{{ attr.key }}:</b> No
                    </template>
                    <template v-else>
                        <b>{{ attr.key }}:</b> Yes
                    </template>
                </div>
                <div v-else-if="attr.key === 'PVPO Number'">
                    <b>PV Number:</b> {{ attr.value }}
                </div>
                <div v-else-if="attr.key === 'Plant Introduction Number'">
                </div>
                <div v-else>
                    <b>{{ attr.key }}:</b> {{ attr.value }}
                </div>

            </template>
        </template>
    </div>
    <br />
    <Button @click="exportResultCsv" label="Export CSV" />
    &nbsp;
    <Button
      @click="toggleShow"
      class="clickable-text p-button-text"
      label="Close"
    />
  </Dialog>
</template>

<style>
    .custom-button {
        background-color: white;
        color: blue;
        border: medium;
        padding: 10px 0px;
        cursor: pointer;
        font-size: 16px;
    }
    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
        border-radius: 5px;
    }
</style>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import { ref, watch, computed } from "vue";

import { exportResultCsv } from "./export";
import store from "@/utils/store";

export default {
  name: "DataModal",
  components: {
    Button,
    Dialog,
  },
  setup() {
    const result = ref(null);
    const resultAttributes = ref(null);
    const varietyName = ref(null);
    const visible = ref(false);
    const answers = ref();
    const isOn = ref(false);
    const buttonText = computed(() => (isOn.value ? 'On' : 'Off'));

    let style = {
      width: "50vw",
    };


    function updateResult() {
      result.value = store.state.dataModalSpecies;
      varietyName.value = result.value.Variety_Name;
      resultAttributes.value = formatDisplayData(result.value);
      isOn.value = false;
    }

    function formatDisplayData(data) {
      let formattedAttributes = [];
      let question, answer;

      for (const [key, value] of Object.entries(data)) {
          //console.log("Key:", key, "value:", value)
          if (key == "Question_Name" || key == "Answer_Name") {
              if (key == "Question_Name") {
                  question = value;
              } else {
                  answer = value;
              }
          } else {
              formattedAttributes.push({
                  key: key.replaceAll("_", " "),
                  value: value,
              });
          }
      }

        if (question && answer) {
            console.log(question)
            let ans = answer.replace(/,/g, '-');
            let temp = question.split(';') + ' ; ' + ans.split(';')

            formattedAttributes.push({
                key: "Questions",
                value: temp,
            });
        }

      return formattedAttributes;
      }

      function isEmpty(value) {
          return (
              value === undefined ||
              value === null ||
              value === '' ||
              (Array.isArray(value) && value.length === 0) ||
              (typeof value === 'object' && value !== null && Object.keys(value).length === 0)
          );
      }


      function showAnswer(index) {
          //console.log(index)
          let answer = answers.value.split(',')
          return answer[index];
      }

      function separateQuestion(attr) {
          const split = attr.split(';')

          let questions = split[0].trim();
          answers.value = split[1].trim();
          //console.log(answers.value);
          questions = questions.split(',')
          return questions;
      }

    watch(() => store.state.dataModalSpecies, updateResult);

    function toggleShow() {
      if (visible.value) {
        store.commit("toggleShowDataModal");
      }

      visible.value = store.state.showDataModal;
    }

    watch(() => store.state.showDataModal, toggleShow);

    return {
      style,
      varietyName,
      resultAttributes,
      visible,
      exportResultCsv,
      toggleShow,
      isEmpty,
      showAnswer,
      separateQuestion,
      isOn,
      buttonText
    };
  },
};

</script>
