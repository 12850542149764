import { API_BASE } from "./constants";

export async function sendAPIRequest(path, params) {
    const URL = API_BASE + '/' + path;
    console.log(URL);
    
  let response = await fetch(URL, params);
  return await response.json();
}


export async function sendAPIRequestV2(path, params) {


    //path = 'request=' + path;
    const url = `${API_BASE}/${path}`;

    let values = Object.values(params);     //Question Answers
    let keys = Object.keys(params);         //Question Name

    //console.log("Vals: " + values);
    //console.log("Keys: " + keys);
    //console.log(Object.values(params).length);
    //let keyStr = values.join('&');
    //let newURL = url + '?' + keys + '=' + values;
    let newURL = url;
    if (values.length > 0) {
        newURL += '?';
    }
    for (let i = 0; i < Object.values(params).length; i++) {
        newURL += keys.at(i) + '=' + values.at(i);
        if (i != Object.values(params).length - 1) {
            newURL += '&';
        }
    }
    console.log(newURL);
    const response = await fetch(newURL, params)
    return await response.json();
}